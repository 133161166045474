import './App.css';


function App() {
  return (
    <div className='Pagina'>
      <div className='Header'>
        <p className='Titolo'>SIUUUUMMMM</p>
        <p className='Testo'>Questo è l'archivio delle vecchie pagine buffe di POLI.RADIOlgfdkgòlskògòlgòLorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri trasferibili “Letraset”, che contenevano passaggi del Lorem Ipsum, e più recentemente da software di impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum.
Perchèl Lorem Ipsum usato sin dal sedicesimo secolo è riprodotto qui di seguito per coloro che fossero interessatlak ovjjskj vlkalkvs kdm vlkmlfòkmòlkmv lakvlkamlkmvflak lkva lk.</p>
      </div>

    <div className='Menu'>
      <button className='Button'>
        siumm
      </button>
      <button className='Button'>
        siummfdj lsjfks
      </button>
      <button className='Button'>
        siummfdj lsjfksflkdsflsm dflk kdjnkfs fklnnkna
      </button>
      <button className='Button'>
        siumm fosfois
      </button>
      <button className='Button'>
        siummfosf ofsknis
      </button>
      <button className='Button'>
        siummfosfofsljfsis
      </button>
      <button className='Button'>
        siummfosfois
      </button>
      <button className='Button'>
        siummfoois
      </button>  
    </div>
    </div>
  );
}

export default App;
